import Layout from 'components/layout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Section from '~v2/section';
import Content from '~v2/blocks/content';
import Media from '~v2/blocks/media';
import Copy from '~v2/elements/copy';
import Box from '~v2/elements/container/box';
import { colors } from 'constants/theme';
import { FlyInFromLeft, FlyInFromBottom } from 'constants/pose';

const Index = ({
  data: {
    homeJson: { hero_section, manifesto_section },
  },
}) => (
  <Layout>
    <Section
      boundsProps={{
        fluid: true,
        className: 'container',
        valign: 'center',
        style: { width: '100%', overflow: 'hidden' },
        TABLET_DOWN: { direction: 'vertical' },
        TABLET: { direction: 'horizontal', padding: '2rem 0 0' },
        DESKTOP_UP: { direction: 'horizontal', padding: '6rem 0 0' },
      }}
      left={{
        children: (
          <Box containerMargin="0 0 0 auto">
            <FlyInFromLeft>
              <Content
                header={{
                  as: 'h1',
                  text: hero_section.title,
                  innerHtml: true,
                }}
                button={{
                  style: 'solid',
                  color: colors.trace_black,
                  text: hero_section.button.text,
                  to: hero_section.button.to,
                }}
              >
                <Copy size="lg">{hero_section.copy}</Copy>
              </Content>
            </FlyInFromLeft>
          </Box>
        ),
        boxProps: {
          fluid: true,
          fill: true,
          shiftRight: true,
          TABLET_DOWN: { width: '100%' },
          TABLET: { width: '60%', padding: '0', margin: '40px -20% 0 0' },
          DESKTOP: { margin: '0 -10% 0 0' },
          DESKTOP_UP: { width: '50%' },
        },
      }}
      right={{
        children: <Media fluid={hero_section.image.childImageSharp.fluid} />,
        boxProps: {
          fluid: true,
          shiftDown: true,
          PHONE: { margin: '-30px 0 0' },
          TABLET_DOWN: { width: '100%' },
          TABLET: { width: '125%', margin: '0 -40% -35px -15%' },
          DESKTOP: { width: '90%', margin: '0 -35% -75px -15%' },
          LG_DESKTOP: { width: '60%', margin: '0 0 -75px' },
        },
      }}
    />
    <Section
      boundsProps={{
        fluid: true,
        valign: 'center',
        style: { width: '100%', overflow: 'hidden' },
        TABLET_DOWN: { direction: 'vertical' },
        TABLET: { direction: 'horizontal', padding: '0' },
        DESKTOP_UP: { direction: 'horizontal', padding: '0' },
      }}
      containerProps={{
        bgColor: colors.warm_grey,
        fontColor: colors.collective_red,
      }}
      left={{
        children: (
          <FlyInFromBottom>
            <Media fluid={manifesto_section.image.childImageSharp.fluid} />
          </FlyInFromBottom>
        ),
        boxProps: {
          fluid: true,
          shiftDown: true,
          className: 'tablet-order-plus-one',
          TABLET_DOWN: { width: '100%', margin: '0 -50% 0 -20%' },
          TABLET: { width: '125%', margin: '3rem -35% 0 -35%' },
          DESKTOP_UP: { width: '50%' },
        },
      }}
      right={{
        children: (
          <Box>
            <FlyInFromBottom>
              <Content
                header={{
                  as: 'h1',
                  variant: 'subheading',
                  textIndent: '-10px',
                  text: manifesto_section.title,
                }}
                button={{
                  style: 'solid',
                  color: colors.collective_red,
                  text: manifesto_section.button.text,
                  to: manifesto_section.button.to,
                  size: 'sm',
                }}
              >
                <Copy size="lg">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: manifesto_section.copy,
                    }}
                  ></div>
                </Copy>
              </Content>
            </FlyInFromBottom>
          </Box>
        ),
        boxProps: {
          fluid: true,
          style: { zIndex: 10 },
          PHONE: { margin: '3rem 0 -4rem' },
          TABLET_DOWN: { width: '100%' },
          TABLET: {
            width: '60%',
            padding: '0 0 0 2rem',
            margin: '0 0rem 0 -2rem',
          },
          DESKTOP_UP: {
            width: '46%',
            padding: '0 0 3rem',
            margin: '0 4% 0 0',
          },
        },
      }}
    />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      hero_section {
        title
        button {
          text
          to
        }
        copy
        image {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      manifesto_section {
        title
        copy
        button {
          text
          to
        }
        image {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
